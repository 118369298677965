import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './axios';

Vue.component('navbar', require('./components/Navbar.vue').default);
Vue.component('foot', require('./components/Foot.vue').default);
Vue.component('jofreqChart', require('./components/SalesChart.vue').default);

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCJte5Sn3yqq-sgzIe0ou8nGpsyof41TK8',
    libraries: 'places',
  }
});

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VueProgressBar from 'vue-progressbar';
Vue.use(VueProgressBar, {
  color: 'rgb(26, 71, 49)',
  failedColor: 'red',
  thickness: '20px',
  location: 'right',
  inverse: true
});

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
  {
    symbol : 'Rp',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

import moment from 'moment'
import vuetify from './plugins/vuetify'
Vue.filter('date', function (date) {
    return moment(date).format('LL');
  })
Vue.filter('sdate', function (date) {
    return moment(date).format('ll');
  })
Vue.filter('ldate', function (date) {
    return moment(date).format('LLL');
  })

require('chart.js');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
