<template>
<v-container fluid style="transform: translateY(30px)">
    <v-row align="center">

    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"><b>Income Statement</b></th>
            <th class="text-left">Januari</th>
            <th class="text-left">Februari</th>
            <th class="text-left">Maret</th>
            <th class="text-left">April</th>
            <th class="text-left">Mei</th>
            <th class="text-left">Juni</th>
            <th class="text-left">Juli</th>
            <th class="text-left">Agustus</th>
            <th class="text-left">September</th>
            <th class="text-left">Oktober</th>
            <th class="text-left">November</th>
            <th class="text-left">Desember</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in sos" :key="item[0]">
            <td style="font-size: 10px" v-if="index==2||index==4||index==6||index==11" class="text-left pl-5"><b>{{ item[0] }}</b></td>
            <td style="font-size: 10px" v-if="index==0||index==1||index==3||index==5||index==7||index==8||index==9
            ||index==10" class="text-left">{{ item[0] }}</td>
            <td style="font-size: 10px">{{ item[1] | currency}}</td>
            <td style="font-size: 10px">{{ item[2] | currency}}</td>
            <td style="font-size: 10px">{{ item[3] | currency}}</td>
            <td style="font-size: 10px">{{ item[4] | currency}}</td>
            <td style="font-size: 10px">{{ item[5] | currency}}</td>
            <td style="font-size: 10px">{{ item[6] | currency}}</td>
            <td style="font-size: 10px">{{ item[7] | currency}}</td>
            <td style="font-size: 10px">{{ item[8] | currency}}</td>
            <td style="font-size: 10px">{{ item[9] | currency}}</td>
            <td style="font-size: 10px">{{ item[10] | currency}}</td>
            <td style="font-size: 10px">{{ item[11] | currency}}</td>
            <td style="font-size: 10px">{{ item[12] | currency}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br>
     <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"><b>Cash Flow Statement Operating</b></th>
            <th class="text-left">Januari</th>
            <th class="text-left">Februari</th>
            <th class="text-left">Maret</th>
            <th class="text-left">April</th>
            <th class="text-left">Mei</th>
            <th class="text-left">Juni</th>
            <th class="text-left">Juli</th>
            <th class="text-left">Agustus</th>
            <th class="text-left">September</th>
            <th class="text-left">Oktober</th>
            <th class="text-left">November</th>
            <th class="text-left">Desember</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in cashs" :key="item[0]">
            <td style="font-size: 10px" v-if="index==2" class="text-left pl-5"><b>{{ item[0] }}</b></td>
            <td style="font-size: 10px" v-if="index==0||index==1" class="text-left">{{ item[0] }}</td>
            <td style="font-size: 10px">{{ item[1] | currency}}</td>
            <td style="font-size: 10px">{{ item[2] | currency}}</td>
            <td style="font-size: 10px">{{ item[3] | currency}}</td>
            <td style="font-size: 10px">{{ item[4] | currency}}</td>
            <td style="font-size: 10px">{{ item[5] | currency}}</td>
            <td style="font-size: 10px">{{ item[6] | currency}}</td>
            <td style="font-size: 10px">{{ item[7] | currency}}</td>
            <td style="font-size: 10px">{{ item[8] | currency}}</td>
            <td style="font-size: 10px">{{ item[9] | currency}}</td>
            <td style="font-size: 10px">{{ item[10] | currency}}</td>
            <td style="font-size: 10px">{{ item[11] | currency}}</td>
            <td style="font-size: 10px">{{ item[12] | currency}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
</v-container>
</template>


<script>
  import axios from "axios"
  export default {
    data: () => ({
      access_token: localStorage.getItem("access_token"),
      cars: [],
      carall: {nopol: 'All',status: '',id: 'All',kir: '',stnk: ''},
      select_car:'',
      sos:[],
      cashs:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {

        axios.get('labarugiopr', {
          headers: {
                Authorization: "Bearer " + this.access_token,
            },
        }).then(({data}) => (this.sos = data[13], this.cashs = data[14]));
      },
      

    },
  }
</script>

