var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-text',[_c('jofreqChart',{ref:"propschild",attrs:{"chartdata":_vm.chartdata,"options":_vm.options}})],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Penjualan Armada")]),_c('th',[_vm._v("Sales")]),_c('th',[_vm._v("Presentase (%)")])])]),_c('tbody',_vm._l((_vm.sales),function(item){return _c('tr',{key:item.armada},[_c('td',[_vm._v(_vm._s(item.armada))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.sales)))]),_c('td',[_vm._v(_vm._s(item.persen)+" %")])])}),0)]},proxy:true}])})],1),_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Frekuensi DO")]),_c('th',[_vm._v("Jumlah")]),_c('th',[_vm._v("Presentase (%)")])])]),_c('tbody',_vm._l((_vm.sales),function(item){return _c('tr',{key:item.armada},[_c('td',[_vm._v(_vm._s(item.armada))]),_c('td',[_vm._v(_vm._s(item.jumlah))]),_c('td',[_vm._v(_vm._s(item.persen_c)+" %")])])}),0)]},proxy:true}])})],1)],1),_c('br'),_c('v-row',[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_sales,"items":_vm.sales_car,"items-per-page":5},scopedSlots:_vm._u([{key:"item.januari",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.januari))+" ")]}},{key:"item.februari",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.februari))+" ")]}},{key:"item.maret",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.maret))+" ")]}},{key:"item.april",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.april))+" ")]}},{key:"item.mei",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.mei))+" ")]}},{key:"item.juni",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.juni))+" ")]}},{key:"item.juli",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.juli))+" ")]}},{key:"item.agustus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.agustus))+" ")]}},{key:"item.september",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.september))+" ")]}},{key:"item.oktober",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.oktober))+" ")]}},{key:"item.november",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.november))+" ")]}},{key:"item.desember",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.desember))+" ")]}}])})]],2)],1),_c('br'),_c('v-row',[_c('v-col',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_freq,"items":_vm.freqs_car,"items-per-page":5}})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }