<script>
    import { Bar } from 'vue-chartjs'
        export default {
        name: "Saleschart",
        props: {
            chartdata: {
            type: Object,
            default: null
            },
            options: {
            type: Object,
            default: null
            }
        },
        extends: Bar,
        data: () => ({
            
        }),
       
        mounted () {
            this.renderChart(this.chartdata, this.options)
        },
        
    }
</script>
