<template>
  <div>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex justify-content-between">

      <div class="logo">
        <a href="/" v-if="!loguser"><img src="assets/img/logo.svg" width="200px" class="img-fluid"></a>
        <router-link to="/car" v-if="loguser"><img src="assets/img/logo.svg" width="200px" class="img-fluid"></router-link>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="/" v-show="$route.name!=='login'" v-if="!loguser">Home</a></li>
          <li><a class="nav-link scrollto" href="#about" v-show="$route.name!=='login'" v-if="!loguser">About</a></li>
          <li><a class="nav-link scrollto" href="#services" v-show="$route.name!=='login'" v-if="!loguser">Services</a></li>
          <li><a class="nav-link scrollto" href="#portfolio" v-show="$route.name!=='login'" v-if="!loguser">Portfolio</a></li>
          <li><a class="nav-link scrollto" href="#contact" v-show="$route.name!=='login'" v-if="!loguser">Contact</a></li>
          <li><router-link to="/login" v-if="!loguser">Admin Login</router-link></li>
          <li><router-link to="/car" v-if="loguser">Fleets</router-link></li>
          <li class="dropdown" v-if="loguser"><a href="#"><span>JO</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link to="/jo" v-if="loguser">JO</router-link></li>
              <li><router-link to="/jowip" v-if="loguser">JO wip</router-link></li>
              <li><router-link to="/joacv" v-if="loguser">JO acv</router-link></li>
              <li><router-link to="/jobatal" v-if="loguser">JO batal</router-link></li>
            </ul>
          </li>
          <li class="dropdown" v-if="loguser"><a href="#"><span>SO</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link to="/so" v-if="loguser">SO</router-link></li>
              <li><router-link to="/soarchieve" v-if="loguser">SO archieve</router-link></li>
            </ul>
          </li>
          <li class="dropdown" v-if="loguser"><a href="#"><span>Sales Report</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link to="/salesreport" v-if="loguser">Sales</router-link></li>
              <li><router-link to="/dncreport" v-if="loguser">Driver And Car</router-link></li>
              <li><router-link to="/labarugi" v-if="loguser">Profit And Loss</router-link></li>
            </ul>
          </li>
          <li class="dropdown" v-if="loguser"><a href="#"><span>Invoice Report</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link to="/invoice" v-if="loguser">Invoice</router-link></li>
              <li><router-link to="/invoiceAR" v-if="loguser">AR</router-link></li>
              <!-- <li><router-link to="/labarugi" v-if="loguser">Profit And Loss</router-link></li> -->
            </ul>
          </li>
          <li><router-link to="/cartracking" v-if="loguser">GPS</router-link></li>
          <li><a class="nav-link scrollto" v-if="loguser" @click="logout">Admin Logout</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
  <!-- ======= Blog Header ======= -->
  <div class="header-bg page-area">
    <div class="container position-relative">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="slider-content text-center">
            <div class="header-bottom">
              <div class="layer2">
                <h1 class="title2"><img src="assets/img/logo.svg" class="img-fluid"></h1>
              </div>
              <div class="layer3">
                <h1 class="title3" style="font-weight: bolder">THE TRANSPORTER</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- End Blog Header -->

  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      access_token: localStorage.getItem("access_token") || null,
      // user: JSON.parse(localStorage["user"]) || null,
    };
  },
  computed: {
    ...mapGetters(["loguser"]),
  },
  methods: {
    logout() {
      localStorage.removeItem("access_token"), localStorage.removeItem("user"), this.$store.dispatch("loguser", null);
      this.$router.push({ name: "home" }).catch(() => {});
    },
    
  },
};
</script>