<template>
    <div style="transform: translateY(30px)">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline services-head text-center">
              <h2>Multi Matra A/R</h2> <br>
              <h3>Total : {{ totalinv | currency}}</h3>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <v-data-table style="font-weight: bolder; font-size: 10px"
                :headers="headers"
                :items="invs"
                :search="search"
                sort-by="id"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Daftar Invoices</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <div class="flex-grow-1"></div>
                        <v-text-field
                        v-model="search"
                        label="search"
                        outlined
                        hide-details
                        class="mr-5"
                        ></v-text-field>

                    </v-toolbar>
                </template>
                <!-- format currency -->
                <template v-slot:item.ppn="{ item }">
                {{item.ppn | currency}}
                </template>
                <template v-slot:item.total="{ item }">
                {{item.total | currency}}
                </template>
                <template v-slot:item.d_inv="{ item }">
                {{item.d_inv | date}}
                </template>
                <template v-slot:item.invtotal="{ item }" activator="{ on }">
                    {{ item.total+item.ppn |currency }} 
                </template>
                <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    edit
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    delete
                </v-icon>
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>
            </v-data-table>
          </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
  export default {
    data: () => ({
      access_token: localStorage.getItem("access_token"),
      date: new Date().toISOString().substr(0, 10),
      headers: [
        {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        { text: 'No. Invoice', value: 'no_inv' },
        { text: 'Customer', value: 'customer.nama' },
        { text: 'Sales', value: 'total' },
        { text: 'PPN', value: 'ppn' },
        { text: 'Total', value: 'invtotal' },
        { text: 'Tanggal Invoice', value: 'd_inv' },
        // { text: 'Actions', value: 'action', sortable: false },
      ],
      headers1: [
       
        { text: 'No. JO', value: 'jo.no_jo' },
        { text: 'Loading', value: 'd_load' },
        { text: 'Unloading', value: 'd_unload' },
        { text: 'No Order', value: 'no_ord' },
        { text: 'Nomor Polisi', value: 'car.nopol' },
        { text: 'Armada', value: 'uang.jenis_car' },
        { text: 'Rute', value: 'uang.rute' },
        { text: 'Driver', value: 'driver.nama' },
        { text: 'No Shipment', value: 'no_shp' },
        { text: 'Sales', value: 'so' },
        { text: 'Add', value: 'add_ch' },
        { text: 'Desc', value: 'desc_ch' },
        { text: 'Total', value: 'total' },
        { text: 'Satuan', value: 'satuan' },
        // { text: 'Actions', value: 'editaction', sortable: false },
      ],
      muatan: '',
      totalppn:'',
      customers: [],
      invoicing: [],
      inv_nama:{},
      update:[],
      inv_id:'',
      invs:[],
      sos:[],
      editsos:[],
      search: '',
      search1: '',
      editedIndex: -1,

    }),

    computed: {
        totalinv: function() {
            return this.invs.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
        },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios.get('customer', {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
        }).then(({data}) => (this.customers = data));
          axios.get('invoice', {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
          }).then(({data}) => (this.invs = data));
      },



      newInvoice () {

        this.btn_add = false
        this.btn_on = false
      },



      Filter (item, queryText) {
          const textOne = item.nama.toLowerCase()
          const searchText = queryText.toLowerCase()
          return textOne.indexOf(searchText) > -1 
        },


    },
  }
</script>
