var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',{attrs:{"id":"main"}},[_c('div',{staticClass:"services-area area-padding",attrs:{"id":"services"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v(_vm._s(_vm.car.nopol))]),_c('br'),_c('h4',[_vm._v("Total JO: "+_vm._s(_vm._f("currency")(_vm.totaljo))+" | Total SO: "+_vm._s(_vm._f("currency")(_vm.totalso)))]),_c('br'),_c('h4',[_vm._v("Gross: "+_vm._s(_vm._f("currency")(_vm.bruto)))])])])]),_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"about-move"},[_c('div',{staticClass:"services-details"},[_c('div',{staticClass:"single-services"},[_c('h4',[_vm._v("Owner: "+_vm._s(_vm.car.owner)+" - Status : "+_vm._s(_vm.car.status)+" - STNK : "+_vm._s(_vm.car.stnk)+" - KIR: "+_vm._s(_vm.car.kir))]),_vm._m(0),[_c('v-data-table',{staticClass:"elevation-1 display-6",attrs:{"dense":"","headers":_vm.header_freq,"items":_vm.freqfilter,"items-per-page":5}})],_c('br'),_vm._m(1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_sales,"items":_vm.salefilter,"items-per-page":5},scopedSlots:_vm._u([{key:"item.januari",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.januari)))])]}},{key:"item.februari",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.februari)))])]}},{key:"item.maret",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.maret)))])]}},{key:"item.april",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.april)))])]}},{key:"item.mei",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.mei)))])]}},{key:"item.juni",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.juni)))])]}},{key:"item.juli",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.juli)))])]}},{key:"item.agustus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.agustus)))])]}},{key:"item.september",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.september)))])]}},{key:"item.oktober",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.oktober)))])]}},{key:"item.november",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.november)))])]}},{key:"item.desember",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"salefilter"},[_vm._v(_vm._s(_vm._f("currency")(item.desember)))])]}}])})]],2)])])])])])]),_c('div',{staticClass:"row text-center mb-4"},[_c('h2',[_vm._v("List of JO")]),_c('div',{staticClass:"container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_jos,"items":_vm.jos,"items-per-page":5},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}}])})],1)]),_c('br'),_c('div',{staticClass:"row text-center"},[_c('h2',[_vm._v("List of SO")]),_c('div',{staticClass:"container mb-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_sos,"items":_vm.sos,"items-per-page":5},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v(" Trip Per Bulan ")]),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v("Sales Per Bulan")]),_c('br')])}]

export { render, staticRenderFns }