var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',{attrs:{"id":"main"}},[_c('div',{staticClass:"services-area area-padding",attrs:{"id":"services"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v("Multi Matra JOACV")]),_c('h4',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaljo)))])])])]),_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"container mb-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.jos,"items-per-page":20},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.nama)+" ")]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.driver.nama)+" ")]}},{key:"item.rute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uang.rute)+" / "+_vm._s(item.uang.c_nama)+" ")]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }