<template>
  <div>
    <main id="main">
    <!-- ======= Services Section ======= -->
    <div id="services" class="services-area area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline services-head text-center">
              <h2>{{car.nopol}}</h2><br>
              <h4>Total JO: {{totaljo | currency}} | Total SO: {{totalso | currency}}</h4><br>
              <h4>Gross: {{bruto | currency}}</h4>
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="about-move">
              <div class="services-details">
                <div class="single-services">
                  <h4>Owner: {{car.owner}} - Status : {{car.status}} - STNK : {{car.stnk}} - KIR: {{car.kir}}</h4>
                  <div class="section-headline services-head text-center">
                    <h2>
                      Trip Per Bulan
                    </h2><br>
                  </div>
                  <template>
                    <v-data-table dense
                      :headers="header_freq"
                      :items="freqfilter"
                      :items-per-page="5"
                      class="elevation-1 display-6"
                    ></v-data-table>
                  </template>
                  
                  <br>
                  <div class="section-headline services-head text-center">
                    <h2>Sales Per Bulan</h2><br>
                  </div>
                  <template>
                    <v-data-table
                      :headers="header_sales"
                      :items="salefilter"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:item.januari="{ item }">
                        <div class="salefilter">{{item.januari | currency}}</div>
                      </template>
                      <template v-slot:item.februari="{ item }">
                        <div class="salefilter">{{item.februari | currency}}</div>
                      </template>
                      <template v-slot:item.maret="{ item }">
                        <div class="salefilter">{{item.maret | currency}}</div>
                      </template>
                      <template v-slot:item.april="{ item }">
                        <div class="salefilter">{{item.april | currency}}</div>
                      </template>
                      <template v-slot:item.mei="{ item }">
                        <div class="salefilter">{{item.mei | currency}}</div>
                      </template>
                      <template v-slot:item.juni="{ item }">
                        <div class="salefilter">{{item.juni | currency}}</div>
                      </template>
                      <template v-slot:item.juli="{ item }">
                        <div class="salefilter">{{item.juli | currency}}</div>
                      </template>
                      <template v-slot:item.agustus="{ item }">
                        <div class="salefilter">{{item.agustus | currency}}</div>
                      </template>
                      <template v-slot:item.september="{ item }">
                        <div class="salefilter">{{item.september | currency}}</div>
                      </template>
                      <template v-slot:item.oktober="{ item }">
                        <div class="salefilter">{{item.oktober | currency}}</div>
                      </template>
                      <template v-slot:item.november="{ item }">
                        <div class="salefilter">{{item.november | currency}}</div>
                      </template>
                      <template v-slot:item.desember="{ item }">
                        <div class="salefilter">{{item.desember | currency}}</div>
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- End Services Section -->



    <!-- list of JO -->
    <div class="row text-center mb-4">
      <h2>List of JO</h2>
      <div class="container">
        <v-data-table
            :headers="header_jos"
            :items="jos"
            :items-per-page="5"
            class="elevation-1"
          >
          <template v-slot:item.total="{ item }">
            {{item.total | currency}}
          </template>
        </v-data-table>      
      </div>
    </div>
    <!-- list of SO -->
    <br>
    <div class="row text-center">
      <h2>List of SO</h2>
      <div class="container mb-4">
        <v-data-table
            :headers="header_sos"
            :items="sos"
            :items-per-page="5"
            class="elevation-1"
          >
          <template v-slot:item.total="{ item }">
            {{item.total | currency}}
          </template>
        </v-data-table>      
      </div>
    </div>


  </main><!-- End #main -->
    
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
            access_token: localStorage.getItem("access_token"),
            car: '',
            sos: [],
            jos: [],
            sales_cars: [],
            freqs_cars: [],
            freqfilter: [],
            salefilter: [],
            header_jos: [
              { text: 'No JO', value: 'no_jo', width: 150 },
              { text: 'Status', value: 'status' },
              { text: 'Total', value: 'total', width: 150 },
              { text: 'Description', value: 'desc_c' },
              { text: 'Driver Id', value: 'driver_id' },
              { text: 'Invoice Id', value: 'invoice_id' },
              { text: 'Uang Id', value: 'uang_id' },
              { text: 'Customer Id', value: 'customer_id' },
              { text: 'Tahun', value: 'year' },
            ],
            header_sos: [
              { text: 'No Ord', value: 'no_ord', width: 350  },
              { text: 'No Shp', value: 'no_shp' },
              { text: 'Total', value: 'total', width: 150 },
              { text: 'Jenis Car', value: 'jenis_car', width: 100 },
              { text: 'Driver Id', value: 'driver_id', width: 100 },
              { text: 'Invoice Id', value: 'invoice_id', width: 100 },
              { text: 'Customer Id', value: 'customer_id', width: 120},
              { text: 'Bulan', value: 'month' },
            ],
            header_freq: [
              // { text: 'DO per Mobil', value: 'nopol' },
              { text: 'Januari', value: 'januari' },
              { text: 'Februari', value: 'februari' },
              { text: 'Maret', value: 'maret' },
              { text: 'April', value: 'april' },
              { text: 'Mei', value: 'mei' },
              { text: 'Juni', value: 'juni' },
              { text: 'Juli', value: 'juli' },
              { text: 'Agustus', value: 'agustus' },
              { text: 'September', value: 'september' },
              { text: 'Oktober', value: 'oktober' },
              { text: 'November', value: 'november' },
              { text: 'Desember', value: 'desember' },
            ],
            header_sales: [
              // { text: 'Sales per Mobil', value: 'nopol' },
              { text: 'Januari', value: 'januari',  },
              { text: 'Februari', value: 'februari' },
              { text: 'Maret', value: 'maret' },
              { text: 'April', value: 'april' },
              { text: 'Mei', value: 'mei' },
              { text: 'Juni', value: 'juni' },
              { text: 'Juli', value: 'juli' },
              { text: 'Agustus', value: 'agustus' },
              { text: 'September', value: 'september' },
              { text: 'Oktober', value: 'oktober' },
              { text: 'November', value: 'november' },
              { text: 'Desember', value: 'desember' },
            ],
            
        }),
        created () {
            
            this.car = this.$attrs.data;
                axios.get("car/"+this.car.id, {
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
                })
                .then((response) => {
                this.car = response.data;
                this.jos = this.car.jos;
                this.sos = this.car.sos;
                })
                .catch((err) => {
                console.log(err);
                this.$swal.fire("Fail!, Something Wrong");
                return;
            });
            
            this.initialize();
            
            
        },
        computed:{
          
          totaljo: function() {
            return this.jos.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
          },
          totalso: function() {
            return this.sos.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
          },
          bruto: function() {
            return this.totalso - this.totaljo
          },
          
        },

        methods: {
            printme() {
                window.print();
            },
            
            initialize() {
              axios.get('chartdnc', {
                  headers: {
                      Authorization: "Bearer " + this.access_token,
                  },
              })
              .then(({ data }) => (
              this.sales_cars = data [7],
              this.freqs_cars = data [8], this.filterfreq(), this.filtersale()
              ))
            },

            filterfreq() {
              for(let i=0; i<this.freqs_cars.length; i++){
              if(this.car.nopol == this.freqs_cars[i].nopol){
                this.freqfilter.push(this.freqs_cars[i]); 
                } 
              }

            },

            filtersale() {
              for(let i=0; i<this.sales_cars.length; i++){
              if(this.car.nopol == this.sales_cars[i].nopol){
                this.salefilter.push(this.sales_cars[i]); 
                } 
              }
            },

            
            
        },
        
	}
    
</script>
<style scoped>
.homeFont {
    font-family: 'Mansalva', cursive; 
    font-size: 5rem;
    /* color: #385F73; */
    color: black
}
.salefilter {
  font-size: 12px;
}
hr {
    border: 10px solid #385F73;
    border-radius: 5px;
    width: 500px;
}
.zoom {
    transition-property: transform;
    transition-duration: 1s;
}
.zoom:hover {
    transform: scale(1.1);
}

</style>
