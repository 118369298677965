var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"transform":"translateY(30px)"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v("Multi Matra Income")]),_vm._v(" "),_c('br'),_c('h3',[_vm._v("Total : "+_vm._s(_vm._f("currency")(_vm.totalbank)))])])]),_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.h_banks,"items":_vm.banks,"search":_vm.bank_search,"items-per-page":10,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{attrs:{"outlined":""}},[_vm._v("Daftar Transfer Cost")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{staticClass:"mr-5",attrs:{"label":"Search","outlined":"","hide-details":""},model:{value:(_vm.bank_search),callback:function ($$v) {_vm.bank_search=$$v},expression:"bank_search"}})],1)]},proxy:true},{key:"item.d_bank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.d_bank))+" ")]}},{key:"item.jmlh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.jmlh))+" ")]}},{key:"item.bank_action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text",attrs:{"small":""},on:{"click":function($event){return _vm.editBank(item)}}},[_vm._v(" edit ")]),_c('v-icon',{staticClass:"red--text",attrs:{"small":""},on:{"click":function($event){return _vm.deleteBank(item)}}},[_vm._v(" delete ")])]}}])})],1),_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v("Multi Matra Income")]),_vm._v(" "),_c('br'),_c('h3',[_vm._v("Total : "+_vm._s(_vm._f("currency")(_vm.totalinv)))])])]),_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invs,"search":_vm.search,"items-per-page":10,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{attrs:{"outlined":""}},[_vm._v("Daftar Perbaikan")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{staticClass:"mr-5",attrs:{"label":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.ppn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.ppn))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.d_inv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.d_inv))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{staticClass:"red--text",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}},{key:"item.close",fn:function(ref){
var item = ref.item;
return [(item.close === 1)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v("Lunas")]):_vm._e(),(item.close === 0)?_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v("Pending")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }