var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"transform":"translateY(30px)"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"section-headline services-head text-center"},[_c('h2',[_vm._v("Multi Matra A/R")]),_vm._v(" "),_c('br'),_c('h3',[_vm._v("Total : "+_vm._s(_vm._f("currency")(_vm.totalinv)))])])]),_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-weight":"bolder","font-size":"10px"},attrs:{"headers":_vm.headers,"items":_vm.invs,"search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Daftar Invoices")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{staticClass:"mr-5",attrs:{"label":"search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.ppn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.ppn))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.d_inv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.d_inv))+" ")]}},{key:"item.invtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total+item.ppn))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }