<template>
<v-container fluid>
    <v-card-text>
      <jofreqChart ref="propschild" :chartdata="chartdata" :options="options"></jofreqChart>
    </v-card-text>

    <v-row>
    <v-col>
      <!-- <p>Penjualan Armada</p> -->
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Penjualan Armada</th>
            <th>Sales</th>
            <th>Presentase (%)</th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="item in sales" :key="item.armada">
            <td>{{ item.armada }}</td>
            <td>{{ item.sales | currency}}</td>
            <td>{{ item.persen }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-col>
    <v-col>
      <!-- <p>Frekuensi Delivery Order Armada</p> -->
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Frekuensi DO</th> 
            <th>Jumlah</th>
            <th>Presentase (%)</th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="item in sales" :key="item.armada">
            <td>{{ item.armada }}</td>
            <td>{{ item.jumlah }}</td>
            <td>{{ item.persen_c }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
      <!-- <p>Sales Armada</p> -->
    <template>
      <v-data-table
        :headers="header_sales"
        :items="sales_car"
        :items-per-page="5"
        class="elevation-1"
      >
      <template v-slot:item.januari="{ item }">
      {{item.januari | currency}}
      </template>
      <template v-slot:item.februari="{ item }">
      {{item.februari | currency}}
      </template>
      <template v-slot:item.maret="{ item }">
      {{item.maret | currency}}
      </template>
      <template v-slot:item.april="{ item }">
      {{item.april | currency}}
      </template>
      <template v-slot:item.mei="{ item }">
      {{item.mei | currency}}
      </template>
      <template v-slot:item.juni="{ item }">
      {{item.juni | currency}}
      </template>
      <template v-slot:item.juli="{ item }">
      {{item.juli | currency}}
      </template>
      <template v-slot:item.agustus="{ item }">
      {{item.agustus | currency}}
      </template>
      <template v-slot:item.september="{ item }">
      {{item.september | currency}}
      </template>
      <template v-slot:item.oktober="{ item }">
      {{item.oktober | currency}}
      </template>
      <template v-slot:item.november="{ item }">
      {{item.november | currency}}
      </template>
      <template v-slot:item.desember="{ item }">
      {{item.desember | currency}}
      </template>
      </v-data-table>
    </template>
    
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
      <!-- <p>Frekuensi Delivery Order Armada</p> -->
    <template>
      <v-data-table
        :headers="header_freq"
        :items="freqs_car"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
    </template>
      </v-col>
    </v-row>
</v-container>
</template>


<script>
  import axios from "axios";
  export default {
    data: () => ({
      access_token: localStorage.getItem("access_token"),
      sales:[],
      sales_car:[],
      freqs_car:[],
      header_sales: [
        { text: 'Sales per Mobil', value: 'nopol' },
        { text: 'Januari', value: 'januari' },
        { text: 'Februari', value: 'februari' },
        { text: 'Maret', value: 'maret' },
        { text: 'April', value: 'april' },
        { text: 'Mei', value: 'mei' },
        { text: 'Juni', value: 'juni' },
        { text: 'Juli', value: 'juli' },
        { text: 'Agustus', value: 'agustus' },
        { text: 'September', value: 'september' },
        { text: 'Oktober', value: 'oktober' },
        { text: 'November', value: 'november' },
        { text: 'Desember', value: 'desember' },
      ],
      header_freq: [
        { text: 'DO per Mobil', value: 'nopol' },
        { text: 'Januari', value: 'januari' },
        { text: 'Februari', value: 'februari' },
        { text: 'Maret', value: 'maret' },
        { text: 'April', value: 'april' },
        { text: 'Mei', value: 'mei' },
        { text: 'Juni', value: 'juni' },
        { text: 'Juli', value: 'juli' },
        { text: 'Agustus', value: 'agustus' },
        { text: 'September', value: 'september' },
        { text: 'Oktober', value: 'oktober' },
        { text: 'November', value: 'november' },
        { text: 'Desember', value: 'desember' },
      ],
      chartdata: {
        labels:['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni',
                'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
        datasets: [
                {
                    label: ['WB'],
                    borderColor: "orange",
                    backgroundColor: 'orange',
                    fill: 'false',
                    data: [],
                },
                {
                    label: ['Fuso'],
                    borderColor: "teal",
                    backgroundColor: 'teal',
                    fill: 'false',
                    data: [],
                },
                {
                    label: ['CDDL/CDD'],
                    borderColor: "blue",
                    backgroundColor: 'blue',
                    fill: 'false',
                    data: [],
                },
                {
                    label: ['CDE'],
                    borderColor: "grey",
                    backgroundColor: 'grey',
                    fill: 'false',
                    data: [],
                },
                {
                    label: ['Blindvan'],
                    borderColor: "purple",
                    backgroundColor: 'purple',
                    fill: 'false',
                    data: [],
                },
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                fontColor: 'Grey',
                fontSize: "20",
                position: "top",
                text: ""
            },
            scales: {   
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 30,
                            callback: function(value) {
                                if(parseInt(value) >= 1000){
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return value;
                                }
                            }
                            
                        }
                    }]
                }
            },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
            
        axios.get('chartdnc', {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
        })
        .then(({ data }) => (
        this.chartdata.datasets[0].data = data[0],
        this.chartdata.datasets[1].data = data[1],
        this.chartdata.datasets[2].data = data[2],
        this.chartdata.datasets[3].data = data[3],
        this.chartdata.datasets[4].data = data[4],
        this.options.title.text = data [5],
        this.sales = data [6],
        this.sales_car = data [7],
        this.freqs_car = data [8]
        ))
        .then(()=>{this.$refs.propschild.renderChart(this.chartdata, this.options)});                   
        
        
      },
    },
  }
</script>