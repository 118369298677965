<template>
    <div class="container mb-4" style="transform: translateY(30px)">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-center mb-4" style="color:black">Please Fill With Your Credential</h2>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <div class="form-group mb-4">
                            <input class="form-control valid" v-model="email" name="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-sm-3"></div>
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <div class="form-group mb-4">
                            <input class="form-control valid" v-model="password" name="password" type="password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter password'" placeholder="Password">
                        </div>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
                <div class="form-group mt-3 text-center">
                    <button @click="handleLogin" class="btn btn-primary">Admin Login</button>
                </div>
                <hr>
            </div>
        </div>
    </div>
  
</template>

<script>
import axios from 'axios';

export default {
  data: ()=> ({
        email: '',
        password: '',
  }),
  created () {

  },

  computed: {

  },
  methods: {
    handleLogin () {
        const sdata = {
        email: this.email,
        password: this.password
        }
        axios.post('login', sdata)
        .then( response => {
            console.log(response.data.user);
            this.access_token = response.data.access_token;
            this.$store.dispatch('loguser', response.data.user);
            localStorage.setItem('access_token', response.data.access_token);
            this.$swal.fire("Success!!, You are login");
            this.$router.push({name: 'car'})
        }
        )
        .catch (
        err => {
            console.log(err)
            this.$swal.fire('Fail!, Check your credential')
            return;
        }
        )    
    },
  }
}
</script>
