<template>
    <div style="transform: translateY(30px)">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-headline services-head text-center">
                <h2>Multi Matra Income</h2> <br>
                <h3>Total : {{ totalbank | currency}}</h3>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <v-data-table
                    :headers="h_banks"
                    :items="banks"
                    :search="bank_search"
                    :items-per-page="10"
                    sort-by="id"
                    class="elevation-1"
                >
                    <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title outlined>Daftar Transfer Cost</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <div class="flex-grow-1"></div>
                        <v-text-field
                            v-model="bank_search"
                            label="Search"
                            outlined
                            hide-details
                            class="mr-5"
                        ></v-text-field>

                    </v-toolbar>
                    </template>
                    <template v-slot:item.d_bank="{ item }">
                    {{item.d_bank | date}}
                    </template>
                    <template v-slot:item.jmlh="{ item }">
                    {{item.jmlh | currency}}
                    </template>
                    <template v-slot:item.bank_action="{ item }">
                    <v-icon
                        small
                        class="mr-2 blue--text"
                        @click="editBank(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                        small
                        class="red--text"
                        @click="deleteBank(item)"
                    >
                        delete
                    </v-icon>
                    </template>
                </v-data-table>  
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-headline services-head text-center">
                <h2>Multi Matra Income</h2> <br>
                <h3>Total : {{ totalinv | currency}}</h3>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <v-data-table
                    :headers="headers"
                    :items="invs"
                    :search="search"
                    :items-per-page="10"
                    sort-by="id"
                    class="elevation-1"
                >
                    <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title outlined>Daftar Perbaikan</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <div class="flex-grow-1"></div>
                        <v-text-field
                            v-model="search"
                            label="Search"
                            outlined
                            hide-details
                            class="mr-5"
                        ></v-text-field>
                        


                        </v-toolbar>
                    
                    </template>

                    <template v-slot:item.ppn="{ item }">
                    {{item.ppn | currency}}
                    </template>
                    <template v-slot:item.total="{ item }">
                    {{item.total | currency}}
                    </template>
                    <template v-slot:item.d_inv="{ item }">
                    {{item.d_inv | date}}
                    </template>

                    <template v-slot:item.action="{ item }">
                    <v-icon
                        small
                        class="mr-2 blue--text"
                        @click="editItem(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                        small
                        class="red--text"
                        @click="deleteItem(item)"
                    >
                        delete
                    </v-icon>
                    </template>
                    <template v-slot:item.close="{ item }">
                        <v-chip color="green" text-color="white" v-if="item.close === 1" >Lunas</v-chip>
                        <v-chip color="red" text-color="white" v-if="item.close === 0" >Pending</v-chip>
                    </template>
                    <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
        access_token: localStorage.getItem("access_token"),
        invs:[],
        banks:[],
        headers: [
          { text: 'No. Invoice', value: 'no_inv' },
          { text: 'Customer', value: 'customer.nama' },
          { text: 'PPN', value: 'ppn' },
          { text: 'Total', value: 'total'},
          { text: 'Tanggal Invoice', value: 'd_inv' },
          { text: 'Lunas', value: 'close' },
        //   { text: 'Actions', value: 'action', sortable: false },
        ],

        h_banks: [
            { text: 'Tanggal', value: 'd_bank' },
            { text: 'Keterangan', value: 'desc_bank', sortable: false },
            { text: 'Jumlah', value: 'jmlh', sortable: false },
            // { text: 'Actions', value: 'bank_action', sortable: false },
        ],
        
        
        }),

        computed: {
            totalinv: function() {
                return this.invs.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
            },
            totalbank: function() {
                return this.banks.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
        },

        created () {
            this.initialize()
        },

        methods: {
            initialize () {
                axios.get('bankinvoice', {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                }).then(({ data }) => (this.banks = data));
                axios.get('invoicelunas', {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                }).then(({data}) => (this.invs = data));
            },
            
        },
    }
</script>