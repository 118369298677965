<template>
  <div>
    <main id="main">
    <!-- ======= Services Section ======= -->
    <div id="services" class="services-area area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline services-head text-center">
              <h2>Multi Matra JO</h2>
              <h4>{{totaljo | currency}}</h4>
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="container mb-4">
            <v-data-table
                :headers="headers"
                :items="jos"
                :items-per-page="20"
                class="elevation-1"
              >
              <template v-slot:item.total="{ item }">
                {{item.total | currency}}
              </template>
              <template v-slot:item.customer="{ item }">
                {{item.customer.nama}}
              </template>
              <template v-slot:item.driver="{ item }">
                {{item.driver.nama}}
              </template>
              <template v-slot:item.rute="{ item }">
                {{item.uang.rute}} / {{item.uang.c_nama}}
              </template>
            </v-data-table>      
          </div>
        </div>     
      </div>
    </div><!-- End Services Section -->


  </main><!-- End #main -->
    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    access_token: localStorage.getItem("access_token"),
    jos: [],
    headers: [
      { text: 'No JO', value: 'no_jo', width: 150 },
      { text: 'Status', value: 'status' },
      { text: 'Total', value: 'total', width: 150 },
      { text: 'Description', value: 'desc_c' },
      { text: 'Driver', value: 'driver' },
      { text: 'Invoice Id', value: 'invoice_id' },
      { text: 'Rute', value: 'rute' },
      { text: 'Customer', value: 'customer' },
      { text: 'Tahun', value: 'year' },
    ],
    
  }),

  computed:{         
    totaljo: function() {
      return this.jos.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        axios.get("listjo1", {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
            })
            .then((response) => {
            this.jos = response.data;
            })
            .catch((err) => {
            console.log(err);
            this.$swal.fire("Fail!, Something Wrong");
            return;
        });
    },
    editJo (jo) {
      console.log('edit jo')
      setTimeout(() => {
        this.editedjo = Object.assign({}, this.defaultjo)
        this.editedIndex = -1
      }, 100)
      this.editedIndex = this.jos.indexOf(jo)
      this.editedjo = Object.assign({}, jo)
      this.$router.push({name: 'jodetail', params: {data: this.editedjo}});
    },

 
    // more Methods
  },
};
</script>
<style scoped>
.homeFont {
    font-family: 'Mansalva', cursive; 
    font-size: 5rem;
    /* color: #385F73; */
    color: black;
  
}
hr {
    border: 10px solid #385F73;
    border-radius: 5px;
    width: 500px;
}
.zoom {
    transition-property: transform;
    transition-duration: 1s;
}
.zoom:hover {
    transform: scale(1.1);
}

</style>
