import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Car from '../views/Car.vue'
import Cardetail from '../views/Cardetail.vue'
import Jo from '../views/Jo.vue'
import Joacv from '../views/Joacv.vue'
import Jowip from '../views/Jowip.vue'
import Jobatal from '../views/Jobatal.vue'
import So from '../views/So.vue'
import Soarchieve from '../views/Soarchieve.vue'
import Salesreport from '../views/Salesreport.vue'
import Dncreport from '../views/Dncreport.vue'
import Labarugi from '../views/Labarugi.vue'
import Invoice from '../views/Invoice.vue'
import InvoiceAR from '../views/InvoiceAR.vue'
import Cartracking from '../views/Cartracking.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/car',
    name: 'car',
    component: Car
  },
  {
    path: '/cardetail',
    name: 'cardetail',
    props: true,
    component: Cardetail
  },
  {
    path: '/jo',
    name: 'jo',
    component: Jo
  },
  {
    path: '/jowip',
    name: 'jowip',
    component: Jowip
  },
  {
    path: '/joacv',
    name: 'joacv',
    component: Joacv
  },
  {
    path: '/jobatal',
    name: 'jobatal',
    component: Jobatal
  },
  {
    path: '/so',
    name: 'so',
    component: So
  },
  {
    path: '/soarchieve',
    name: 'soarchieve',
    component: Soarchieve
  },
  {
    path: '/salesreport',
    name: 'salesreport',
    component: Salesreport
  },
  {
    path: '/dncreport',
    name: 'dncreport',
    component: Dncreport
  },
  {
    path: '/labarugi',
    name: 'labarugi',
    component: Labarugi
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: Invoice
  },
  {
    path: '/invoiceAR',
    name: 'invoiceAR',
    component: InvoiceAR
  },
  {
    path: '/cartracking',
    name: 'cartracking',
    component: Cartracking
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
