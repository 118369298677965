<template>
  <div>
    <main id="main">
      <div id="services" class="services-area area-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="section-headline services-head text-center">
                <h2>GPS Tracking</h2>
              </div>
            </div>
          </div>
        </div>
      </div><!-- End Services Section -->
      <div>
        <gmap-map :center='startLocation' :zoom='7' style='width:100%;  height: 600px;'>
          <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened" @closeclick="infoOpened=false">
            {{infoContent}}
          </gmap-info-window>
          <gmap-marker v-for="(item, key) in trackers" :key="key" :position="getPosition(item)" :clickable="true" @click="toggleInfo(item, key)" />
        </gmap-map>
      </div>
    </main><!-- End #main -->
  </div>
</template>

<script>

import axios from "axios";

export default {
  
  data: () => ({
    access_token: localStorage.getItem("access_token"),
    cars: [],
    trackers: [],
    editedIndex: -1,
    editedcar: {
        id: '',
        nopol: '',
        owner: '',
        kir:'',
        status:'',
        stnk: '',
        muatan:[],
        
      },
      defaultcar: {
        id: '',
        nopol: '',
        owner: '',
        kir:'',
        status:'',
        stnk: '',
        muatan:[],
       
      },

      startLocation: {
        lat:  -6.966667,
        lng: 110.416664
      },
      
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    
  }),

  computed: {

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        axios.get("car", {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
            })
            .then((response) => {
            this.cars = response.data
            })
            .catch((err) => {
            console.log(err);
            this.$swal.fire("Fail!, Something Wrong");
            return;
        });
        axios.get("https://api.orin.id/orin/devices?api_key=VDIbYUQ8AR9VA94GIJxUCL8snGjPLhecheN37LkyxDp120dI", {
          headers : {
            "Access-Control-Allow-Origin": "*"
          }
        })
            .then((response) => {
            this.trackers = response.data;
            })
            .catch((err) => {
            console.log(err);
            this.$swal.fire("Fail!, Something Wrong");
            return;
        });
          
    },
    editCar (car) {
      console.log('edit car')
      setTimeout(() => {
        this.editedcar = Object.assign({}, this.defaultcar)
        this.editedIndex = -1
      }, 100)
      this.editedIndex = this.cars.indexOf(car)
      this.editedcar = Object.assign({}, car)
      this.$router.push({name: 'cardetail', params: {data: this.editedcar}});
    },

    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.last_location.lat),
        lng: parseFloat(marker.last_location.lng)
      }
    },
    toggleInfo: function(marker, key) {
      this.infoPosition = this.getPosition(marker)
      this.infoContent = '[ '+marker.device_name+' ]-[ '+marker.device_status+' ]-[ '+marker.last_data.dt+' ]'
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened
      } else {
        this.infoOpened = true
        this.infoCurrentKey = key
      }
    }
    // more Methods
  },
};
</script>
<style scoped>
.homeFont {
    font-family: 'Mansalva', cursive; 
    font-size: 5rem;
    /* color: #385F73; */
    color: black;
  
}
hr {
    border: 10px solid #385F73;
    border-radius: 5px;
    width: 500px;
}
.zoom {
    transition-property: transform;
    transition-duration: 1s;
}
.zoom:hover {
    transform: scale(1.1);
}


</style>
