import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loguser: null,
    users: null,
  },

  getters: {
    loguser: (state) => {
      return state.loguser;
    },
    users: (state) => {
      return state.users;
    },
  },

  actions: {
    loguser(context, loguser) {
      context.commit("loguser", loguser);
    },
    users(context, users) {
      context.commit("users", users);
    },
  },

  mutations: {
    loguser(state, loguser) {
      state.loguser = loguser;
    },
    users(state, users) {
      state.users = users;
    },
  },
});

