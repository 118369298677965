<template>
    
    <div class="container mt-4 pa-4">
        <v-container>
            <v-card-text>
            <jofreqChart ref="propschild" :chartdata="chartdata" :options="options"></jofreqChart>
            </v-card-text>
            <v-row align="center">
            <v-col class="d-flex" cols="12" sm="6">
                <v-autocomplete v-model="select_car"
                        :items="cars"
                        required    
                        row
                        filled chips 
                        label="Pilih Kendaraan" 
                        item-value="id" 
                        item-text="nopol" 
                        @change="carData()"
                        
                        >
                    <template slot="selection" slot-scope="data">
                    <v-chip :input-value="data.selected" >
                    {{data.item.nopol}}
                    </v-chip>
                </template>
                <template slot="item" slot-scope="data">
                    <v-list-item-content>
                    <v-list-item-title v-html="data.item.nopol"></v-list-item-title>
                    </v-list-item-content>
                </template>
                </v-autocomplete>
            </v-col>
            </v-row>

            <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Nama</th>
                    <th class="text-left">Sales</th>
                    <th class="text-left">Presentase</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in sos" :key="item.nama">
                    <td>{{ item.nama }}</td>
                    <td>{{ item.sales | currency }}</td>
                    <td>{{ item.presentase }} %</td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
        </v-container>
        <!-- <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th align="left">Nama</th>
                            <th align="right">Sales</th>
                            <th align="right">Presentase</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in sos" :key="item.nama">
                            <td align="left">{{ item.nama }}</td>
                            <td align="right">{{ item.sales | currency }}</td>
                            <td align="right">{{ item.presentase }} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
</template>


<script>
    import axios from "axios";
    export default {
        data: () => ({
        access_token: localStorage.getItem("access_token"),
        cars: [],
        carall: {nopol: 'All',status: '',id: 'All',kir: '',stnk: ''},
        select_car:'',
        // data_table:[],
        sos:[],
        chartdata: {
            labels:['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni',
                    'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
            datasets: [
                    {
                        label: ['Sales'],
                        borderColor: "orange",
                        backgroundColor: 'orange',
                        fill: 'false',
                        data: [],
                    },
                    {
                        label: ['HPP'],
                        borderColor: "brown",
                        backgroundColor: 'brown',
                        fill: 'false',
                        data: [],
                    },
                    {
                        label: ['Gross Profit'],
                        borderColor: "teal",
                        backgroundColor: 'teal',
                        fill: 'false',
                        data: [],
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    fontColor: 'Grey',
                    fontSize: "20",
                    position: "top",
                    text: ""
                },
                scales: {   
                        yAxes: [{
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 30,
                                callback: function(value) {
                                    if(parseInt(value) >= 1000){
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    } else {
                                        // return 'Rp.' + value;
                                        return value;
                                    }
                                }
                            }
                        }]
                    }
                },
        }),

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'New User' : 'Edit User'
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
        },

        created () {
        this.initialize()
        },

        methods: {
        initialize () {
            // get data
            axios.get('carall',{      
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            }).then(({data}) => (this.cars = data, this.cars.push(this.carall)));
            axios.get('chart', {
                    headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then(({ data }) => (
            this.chartdata.datasets[0].data = data[0],
            this.chartdata.datasets[1].data = data[1],
            this.chartdata.datasets[2].data = data[2],
            this.options.title.text = data [3],
            this.sos = data [5]
            ))
            .then(()=>{this.$refs.propschild.renderChart(this.chartdata, this.options)});                   
        },
            

        carData(){
            axios.get('carall',{
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            }).then(({data}) => (this.cars = data, this.cars.push(this.carall)));
            axios.post('carchart',{car_input: this.select_car},{
                 headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then(({ data }) => (
            this.chartdata.datasets[0].data = data[0],
            this.chartdata.datasets[1].data = data[1],
            this.chartdata.datasets[2].data = data[2],
            this.options.title.text = data [3]
            ))
            .then(()=>{this.$refs.propschild.renderChart(this.chartdata, this.options)});                   
    
        }
        

        },
    }
</script>
