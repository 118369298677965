<template>
  <div>
    <main id="main">
    <!-- ======= Services Section ======= -->
    <div id="services" class="services-area area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline services-head text-center">
              <h2>Multi Matra SO ARCHIEVE</h2>
              <h4>{{totalso | currency}}</h4>
              <div class="container navbar">        
                <label class="checkbox-inline"><input type="checkbox"  v-model="checkbox_month" @change="selectAll"> Select All</label>
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="1" @change="unCheck"> Januari</label>
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="2" @change="unCheck"> Pebuari</label>     
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="3" @change="unCheck"> Maret</label>    
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="4" @change="unCheck"> April</label>          
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="5" @change="unCheck"> Mei</label>            
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="6" @change="unCheck"> Juni</label>           
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="7" @change="unCheck"> Juli</label>              
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="8" @change="unCheck"> Agustus</label>           
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="9" @change="unCheck"> September</label>                
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="10" @change="unCheck"> Oktober</label>              
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="11" @change="unCheck"> Nopember</label>
                <label class="checkbox-inline"><input type="checkbox"  v-model="so_month" value="12" @change="unCheck"> Desember</label>  
              </div>
            </div>
          </div>
        </div>

       <div class="row text-center">
          <div class="container mb-4">
            <v-data-table
                :headers="headers"
                :items="sos"
                :items-per-page="20"
                class="elevation-1"
              >
              <template v-slot:item.total="{ item }">
                {{item.total | currency}}
              </template>
              <template v-slot:item.nojo="{ item }">
                {{item.jo.no_jo}}
              </template>
              <template v-slot:item.nopol="{ item }">
                {{item.car.nopol}}
              </template>
              <template v-slot:item.driver="{ item }">
                {{item.driver.nama}}
              </template>
              <template v-slot:item.rute="{ item }">
                {{item.uang.rute}} / {{item.uang.c_nama}}
              </template>
            </v-data-table>      
          </div>
        </div>
      </div>
    </div><!-- End Services Section -->


  </main><!-- End #main -->
    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    access_token: localStorage.getItem("access_token"),
    sos: [],
    so_month:[],
    so_all:["1","2","3","4","5","6","7","8","9","10","11","12"],
    headers: [
        { text: 'No JO', value: 'nojo', width: 150  },
        { text: 'No Pol', value: 'nopol' },
        { text: 'Total', value: 'total', width: 150},
        { text: 'Rute', value: 'rute', width: 250 },
        { text: 'Driver', value: 'driver', width: 200 },
        { text: 'Invoice Id', value: 'invoice_id', width: 100 },
        { text: 'Jenis Car', value: 'jenis_car', width: 120},
        { text: 'Bulan', value: 'month' },
      ],
    
  }),

  computed:{         
    totalso: function() {
      return this.sos.reduce(function(a, c){return a + Number((c.total) || 0)}, 0)
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        axios.get("achvso", {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
            })
            .then((response) => {
            this.sos = response.data;
            })
            .catch((err) => {
            console.log(err);
            this.$swal.fire("Fail!, Something Wrong");
            return;
        });
    },
    editSo (so) {
      console.log('edit so')
      setTimeout(() => {
        this.editedso = Object.assign({}, this.defaultso)
        this.editedIndex = -1
      }, 100)
      this.editedIndex = this.sos.indexOf(so)
      this.editedso = Object.assign({}, so)
      this.$router.push({name: 'sodetail', params: {data: this.editedso}});
    },
    selectAll (){
        if (this.checkbox_month == true) {
            this.so_month = this.so_all;
        }else{
            this.so_month = []
        }
        axios.post('sofilter', {months:this.so_month},
        {
          headers: {
                Authorization: "Bearer " + this.access_token,
            },
        }).then(({ data }) => (this.sos = data));
    },
    unCheck (){
        if (this.so_month != this.so_all){
            this.checkbox_month = false;
        }
        axios.post('sofilter', {months:this.so_month},
        {
          headers: {
                Authorization: "Bearer " + this.access_token,
            },
        }).then(({ data }) => (this.sos = data));
    },
 
    // more Methods
  },
};
</script>
<style scoped>
.homeFont {
    font-family: 'Mansalva', cursive; 
    font-size: 5rem;
    /* color: #385F73; */
    color: black;
  
}
hr {
    border: 10px solid #385F73;
    border-radius: 5px;
    width: 500px;
}
.zoom {
    transition-property: transform;
    transition-duration: 1s;
}
.zoom:hover {
    transform: scale(1.1);
}

</style>
