<template>
  <div>
    <main id="main">
    <!-- ======= Services Section ======= -->
    <div id="services" class="services-area area-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline services-head text-center">
              <h2>Multi Matra Fleets</h2>
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-md-4 col-sm-4 col-xs-12" v-for="car in cars" :key="car.id">
            <div class="about-move">
              <div class="services-details">
                <div class="single-services">
                  <a class="services-icon" @click="editCar(car)">
                    <i class="bi bi-truck"></i>
                  </a>
                  <h4 @click="editCar(car)">{{car.nopol}} ({{car.owner}}) ({{car.status}})</h4>
                  <div class="row text-center">
                    <p class="col-md-4 col-sm-4 col-xs-12" v-for="muatan in car.muatan" :key="muatan.id">
                      {{muatan.m_load}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- End Services Section -->


  </main><!-- End #main -->
    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    access_token: localStorage.getItem("access_token"),
    cars: [],
    editedIndex: -1,
    editedcar: {
        id: '',
        nopol: '',
        owner: '',
        kir:'',
        status:'',
        stnk: '',
        muatan:[],
        
      },
      defaultcar: {
        id: '',
        nopol: '',
        owner: '',
        kir:'',
        status:'',
        stnk: '',
        muatan:[],
       
      },
    
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        axios.get("car", {
            headers: {
                Authorization: "Bearer " + this.access_token,
            },
            })
            .then((response) => {
            this.cars = response.data;
            })
            .catch((err) => {
            console.log(err);
            this.$swal.fire("Fail!, Something Wrong");
            return;
        });
          
    },
    editCar (car) {
      console.log('edit car')
      setTimeout(() => {
        this.editedcar = Object.assign({}, this.defaultcar)
        this.editedIndex = -1
      }, 100)
      this.editedIndex = this.cars.indexOf(car)
      this.editedcar = Object.assign({}, car)
      this.$router.push({name: 'cardetail', params: {data: this.editedcar}});
    },

 
    // more Methods
  },
};
</script>
<style scoped>
.homeFont {
    font-family: 'Mansalva', cursive; 
    font-size: 5rem;
    /* color: #385F73; */
    color: black;
  
}
hr {
    border: 10px solid #385F73;
    border-radius: 5px;
    width: 500px;
}
.zoom {
    transition-property: transform;
    transition-duration: 1s;
}
.zoom:hover {
    transform: scale(1.1);
}

</style>
